.parametre-container {
  width: 600px;
  margin: auto;
  color: #17418d;
}

@media only screen and (orientation: portrait) {
  .parametre-container {
    width: 100%;
  }
}
