.chat-send {
  /* background-color: aqua; */
  width: 100%;
  height: fit-content;
  margin-top: 1%;
}

.chat-send .chat-box {
  background-color: #e4e4e4;
  color: #17418d;
  max-width: 70%;
  height: fit-content;
  width: fit-content;
  padding: 1%;
  padding-left: 4%;
  border-radius: 20px 20px 0px 20px;
  position: relative;
  margin-bottom: 2%;
  word-break: break-word;
}

.chat-send .chat-box::before {
  content: "";
  position: absolute;
  top: 100%;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 1vh solid transparent;
  border-right: 1vh solid #e4e4e4;
  border-top: 1vh solid #e4e4e4;
  border-bottom: 1vh solid transparent;
}

.chat-receive {
  /* background-color: aqua; */
  width: 100%;
  height: fit-content;
  margin-top: 1%;
}

.chat-receive .chat-box {
  background-color: #d2e9fb;
  color: #17418d;
  max-width: 70%;
  height: fit-content;
  width: fit-content;
  padding: 1%;
  padding-right: 4%;
  /* padding-right: 1%; */
  border-radius: 0px 20px 20px 20px;
  position: relative;
  margin-bottom: 2%;
  word-break: break-word;
}

.chat-receive .chat-box::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 1vh solid #d2e9fb;
  border-right: 1vh solid transparent;
  border-top: 1vh solid transparent;
  border-bottom: 1vh solid #d2e9fb;
}

.chat-container {
  padding-bottom: 10%;
  /* border: 1px solid rgb(228, 226, 226); */
  height: 85vh !important;

  overflow-y: scroll;
  margin: auto;
}

/* .fixed-navbar {
    background-color: aqua;
    margin-top: 12vh;
} */

/* Personnaliser scrollbar */
.chat-container::-webkit-scrollbar {
  /* display: none; */
  /* background-color: #17418d; */
  /* border-radius: 1px; */
}

.msgListBox {
  width: 600px;
  height: 100%;
  /* background-color: #17418d; */
}

.messageInput::placeholder {
  color: #17418d !important;
  opacity: 0.8;
}

textarea {
  outline: none;
}

textarea:focus {
  /* border: .9px solid #17418d !important; */
  /* border: none !important; */
}

.saisie-message {
  top: 100%;
}

@media only screen and (orientation: portrait) {
  .msgListBox {
    width: 100%;
    font-size: 0.85rem;
  }

  .chat-receive .chat-box {
    max-width: 90%;
  }

  .chat-send .chat-box {
    max-width: 90%;
  }
}
