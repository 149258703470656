.container-participants {
  /* background-color: aqua; */
  /* width: 10%; */
  margin: auto;
  color: #17418d;
  width: 600px;
}

.participant-item {
  /* border: 2px solid red; */
  margin: auto;
  margin-bottom: 20px;
  border-radius: 5rem;
  box-shadow: 0px 0px 5px black;
  padding: 1%;
  /* font-family: 'Poppins-light'; */
  /* width: 500px; */
}

/* .participant-item:hover {
    transform: rotate(.002turn);
} */

.participant-item a {
  color: #17418d;
}

.participant-item a :hover {
  color: #3c2e94;
}

.intervention-temps {
  background-color: #009af4;
  color: white;
}

.intervention-titre {
  margin: auto;
  text-align: center;
  color: #00a7d5;
  font-size: xx-large;
  font-family: "Barlow-bold";
}

.footer-profil {
  background-color: #f0f1f1;
}

.recherche-container-participant {
  width: 98%;
  /* margin: auto; */
  /* background-color: #17418d; */
}

.noms-participant {
  /* font-weight: bold; */
  font-family: "Barlow-bold";
  font-size: 102%;
}

.photo-profil-participant {
  width: fit-content;
}

.buttons-pagination {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: #17418d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Barlow-bold";
}

.buttons-pagination:disabled {
  background-color: #e4e4e4;
  color: #17418d;
}

/* .navbar-container-participant {
    background: url('../assets/img/DSC_0700.jpg');
    box-shadow: inset 0 0 0 2000px rgba(39, 29, 103, 0.50);
    border-radius: 0 0 8rem 0;
} */

.participantTitle {
  color: white;
  text-align: center;
  margin: auto;
  font-size: 2rem;
  font-family: "barlow-bold";
  position: relative;
}

.participant-picto {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6vh;
}

.avatar-profil {
  background-color: #e4e4e4;
}

@media only screen and (orientation: portrait) {
  .container-participants {
    width: 100%;
  }

  .participant-item {
    font-size: 2.9vw;
    width: 100%;
  }

  .avatar-profil {
    /* background-color: #17418d; */
    width: 100px;
  }
}
