.partenaire-item {
  /* border: 2px solid red; */
  margin: auto;
  margin-bottom: 20px;
  box-shadow: 0px -8px 10px -10px #17418d;
  /* border-radius: 1.5rem; */
  /* height: 100px; */
  /* border-top: 2px solid black; */
  color: #17418d;
}

.partenaire-item:hover {
  box-shadow: 0px -8px 10px -7px#17418d;
}

.container-exposants {
  margin: auto;
  width: 600px;
}

@media (max-width: 817px) {
  .container-exposants {
    width: 100%;
  }
}

@media only screen and (orientation: portrait) {
  .partenaire-item {
    width: 100%;
    font-size: 0.75rem;
  }

  .container-exposants {
    width: 100%;
  }
}
