.programme-container {
  width: 800px;
  margin: auto;
  color: #17418d;
}

.programme-detail {
  /* border-bottom: 2px solid #00a7d5; */
  margin-bottom: 1%;
  padding-bottom: 1%;
}

.programme-item {
  margin-bottom: 20px;
  border-radius: 1.5rem;
  box-shadow: 0px 0px 5px #17418d;
  padding-bottom: 10px;
  padding-right: 15px;
  width: 100%;
}

.programme-item img {
  /* width: 30%; */
  width: 60px;
}

.programme-item a {
  text-decoration: none;
  color: #17418d;
}

.programme-item .programme-date {
  /* background-color: blueviolet; */
  color: white;
  width: fit-content;
  height: fit-content;
  padding: 4px 8px 0px 8px;
}

.programme-titre {
  /* font-weight: bold; */
  font-family: "Barlow-bold";
  margin-bottom: 10px;
  /* font-size: 1.2rem; */
  font-size: 125%;
}

.programme-description {
  /* height: 55px; */
}

.intervenants-container {
  /* color: #00a7d5; */
  /* border-bottom: 2px solid #00a7d5; */
  height: min-content;
  margin-bottom: 1%;
}

.participants-container {
  width: 50%;
}

.recherche-container {
  width: 600px !important;
}

.menu-filtre ul {
  font-family: "Barlow";
  color: #17418d;
}

.menu-filtre {
  width: 100%;
}

.intervenant-item {
  margin-bottom: 1%;
  padding-bottom: 1%;
  width: 100%;
}

.rdv-container {
  width: 600px;
  margin: auto;
}

.stand2 {
  display: none;
}

@media (max-width: 412px) {
  .stand2 {
    display: flex;
  }

  .stand1 {
    opacity: 0;
  }
}

@media (max-width: 817px) {
  .programme-container {
    width: 100%;
    font-size: 0.85rem;
  }
}

@media only screen and (orientation: portrait) {
  .programme-container {
    width: 100%;
  }

  .recherche-container .barre-recherche {
    width: 80%;
  }

  .participants-container {
    width: 100%;
  }

  .intervenants-container {
    width: 100%;
  }
}
