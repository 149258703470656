.dashboardTitle {
  font-size: 2rem;
  /* font-weight: 600; */
  font-family: "Barlow-bold";
  margin: auto;
  text-align: center;
  color: white;
}

.dashboardMenu {
  width: 800px;
  /* background-color: #009af4; */
}

/* .dashboardMenu a {
    color: black;
    text-decoration: none;
} */

.dashboardMenu img {
  width: 7rem;
}

.dashboardMenu .col {
  width: fit-content;
  color: white;
  /* font-weight: 600; */
  margin: 2%;
  margin-bottom: 4%;
}

.menu-container {
  text-align: center;
  margin: auto;
  width: 700px;
  padding: 0%;
}

.menu-container a {
  text-decoration: none;
  color: white;
}

.menu-container img {
  margin: auto;
}

.dashboard-item {
  width: 164px;
  height: 150px;
  border-radius: 2rem;
  text-align: center;
  /* margin: auto; */
}

.program-container {
  background-color: #f8ad41;

  /* font-size: .95rem */
}

.partenaire-container {
  background-color: #1bace4;

  /* font-size: .95rem */
}

.journee-container {
  background-color: #e84d14;

  /* font-size: .95rem */
}

.participant-container {
  background-color: #0078bc;

  /* font-size: .95rem */
}

.stand-container {
  background-color: #c01630;

  /* font-size: .95rem */
}

.premium-list {
  /* width: 80px;
    height: 80px; */
  /* background-color: #009af4; */
  /* border: 1px solid #856ab1 */
  width: fit-content;
  margin: auto;
  display: flex;
  justify-content: center;
}

.premium-list .col {
  /* margin-left: 50px; */
  /* width: 100%; */
}

@media only screen and (orientation: portrait) {
  .dashboardTitle {
    font-size: 1.8rem;
  }

  .dashboardMenu {
    width: 100%;
    /* padding: 0; */
  }
}
