.contact-container {
  color: #17418d;
  width: 800px;
  margin: auto;
  border-top: 2px solid #00a7d5;
  border-bottom: 2px solid #00a7d5;
  padding-top: 10px;
}
.contact-container span {
  /* background-color: #00a7d5; */
  font-family: "Barlow" !important;
}

.nous-contacter-container {
  color: #17418d;
  width: 800px;
  margin: auto;
  padding-top: 10px;
}

.mentions-container {
}

@media (max-width: 829px) {
  .contact-container {
    width: 100%;
  }

  .nous-contacter-container {
    width: 100%;
  }
}
