.cloture-container {
  width: 800px;
  margin: auto;
  color: #17418d;
  /* margin-top: 2%; */
}

@media (max-width: 829px) {
  .cloture-container {
    width: 100%;
  }
}
