@font-face {
  font-family: "Barlow";
  src: local("Barlow"),
    url("../public/fonts/Barlow-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-bold";
  src: local("Barlow"),
    url("../public/fonts/Barlow-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-light";
  src: local("Barlow"),
    url("../public/fonts/Barlow-Light.ttf") format("truetype");
}

/* @font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../public/fonts/Poppins-Medium.ttf") format("truetype");
} */

@font-face {
  font-family: "Poppins-bold";
  src: local("Poppins"),
    url("../public/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-light";
  src: local("Poppins"),
    url("../public/fonts/Poppins-Light.ttf") format("truetype");
}

#root {
  /* margin: 0; */
  font-family: "Barlow" !important;
}

button {
  border: none !important;
}

input {
  border: none !important;
}

p {
  margin-bottom: 0% !important;
}

* {
  margin: 0;
}

.modal-body {
  color: #17418d;
}

.MuiAlert-message {
  font-family: "Barlow" !important;
  color: #e84d14 !important;
}
