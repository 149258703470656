.container-messagerie {
  width: 600px;
  /* background-color: rgb(245, 240, 240); */
  margin: auto;
}

.container-messagerie a {
  text-decoration: none;
  color: #17418d;
}

.recherche-container {
  width: 500px !important;
  /* background-color: rgb(245, 240, 240); */
}

.message-item {
  margin: auto;
  margin-bottom: 20px;
  border-radius: 2rem;
  box-shadow: 0px 0px 5px #17418d;
  /* padding: 1%; */
  font-size: 100%;
  padding-top: 15px;
  /* padding-bottom: 17px; */
  /* font-size: 100%; */
  /* height: 100px; */
}

.btn-create-rdv:hover {
  background-color: white !important;
}

@media only screen and (orientation: portrait) {
  .container-messagerie {
    width: 100%;
    /* background-color: rgb(245, 240, 240); */
  }

  .recherche-container {
    width: 100%;
    /* background-color: rgb(245, 240, 240); */
  }

  .message-item {
    width: 100%;
  }

  .message-item {
    font-size: 0.85rem;
  }
}
