.evenements a {
  color: white;
  text-decoration: none;
  margin: auto;
  width: 30%;
}

.evenements {
  margin-top: 30px !important;

  margin: auto;
  width: fit-content;
  height: "auto";
}

.evenements .evenement {
  margin: auto;
  width: min-content;
  /* background-color: #00a7d5;
  height: 100%; */
}

.evenement img {
  width: 300px;
  height: 170px;
  object-fit: fill;
  padding: 10px;
}

.evenement a img:hover {
  box-shadow: 0px 2px 10px rgb(201, 200, 200);
}

.evenements a .card {
  height: 11rem !important;
}

.evenements a img {
  border-radius: 2rem;
  box-shadow: 0px 2px 10px rgb(235, 234, 234);
}

.texte-choix-evt {
  text-align: center;
  font-size: 2rem;
  /* font-weight: 800; */
  font-family: "Barlow-bold";
  color: #00a7d5;
}

.texte-intro-neo {
}

.access-wording {
  color: #fff;
  width: 50%;
  text-align: center;
  margin-top: 10px;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (orientation: portrait) {
  .texte-choix-evt {
    font-size: 2rem;
  }
}
@media (max-width: 500px) {
  .access-wording {
    width: 100%;
  }
  .logo-container {
    flex-direction: column;
  }
}
