.navbar-home a {
  text-align: left;
  text-decoration: none;
  /* background-color: #4a00b5; */
  width: fit-content;
}

.navbar-home {
  margin-top: -0.4% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  /* height: 120px; */
}

/* .navbar-home .collapse a {


    height: fit-content;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 0.86rem;
} */

.navbar-home .collapse a :hover {
  color: rgb(255, 255, 255);
}

.navbar-home .collapse a {
  font-size: 1.3vw;
}

.navbar-collapse {
  /* height: fit-content !important; */
  margin: auto;
  margin-top: 0;
  padding-top: 2%;
  /* background-color: red; */

  /* height: 100%; */
}

.icone {
  /* background-color: #17418d; */
}

.navbar-container {
  background: url("../assets/img/bandeau.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* box-shadow: inset 0 0 0 2000px rgba(24, 17, 75, 0.8); */
  border-radius: 0 0 9rem 0;
}

.logoHome {
}

.collapse-menu {
  width: 100%;
}

.collapse-item {
  /* width: fit-content; */
  /* background-color: #4a00b5; */
}

.icone {
  width: 35px;
  height: 35px;
}

.texteMenus {
  color: white;
  text-align: left;
  /* padding-left: 10px; */
  font-size: 1.1vw;
  font-family: "Barlow-light";
}

.nav-item {
  margin-right: 2%;
}

.menu-profil {
  margin-top: 2% !important;
  margin-left: 0%;
}

.menu-profil ul {
  /* background-color: #4a00b5; */
  color: #17418d !important;
}

.menu-profil a {
  /* background-color: #4a00b5; */
  color: #17418d !important;
}

.menu-dashboard1 .Joy-selected {
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* color: #4a00b5 !important; */
  /* background-color: aqua !important;
    font-family: 'Times New Roman' !important; */
  /* width: auto; */
  /* display: none; */
  background-color: rgb(201, 201, 201);
}

.menu-dashboard .Joy-selected {
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* color: #4a00b5 !important; */
  /* background-color: aqua !important;
    font-family: 'Times New Roman' !important; */
  /* width: auto; */
  /* display: none; */
  background-color: rgb(201, 201, 201);
}

.menu-dashboard li {
  display: none;
}

#btn-dash {
  display: none;
}

.menu-profil ul a {
  text-decoration: none;
  color: white;
}

.evenement-navbar-icone {
  /* border-radius: 0 0 1.5rem 1.5rem; */
  width: 180px;
  height: 100px;
  margin-bottom: 10px;
  margin-left: 4px;
}

.btn-dashboard {
  z-index: 1;
}

.btn-accepter-demande-notif {
  background-color: rgb(221, 221, 221);
  color: #17418d;
  display: flex;
  justify-content: center;
  border-radius: 3rem;
  width: fit-content;
  height: fit-content;
  padding-left: 1.5%;
  padding-right: 1.5%;
}

.btn-accepter-demande-notif:hover {
  background-color: rgb(190, 190, 190);
}

.btn-refuser-demande-notif {
  background-color: rgb(241, 241, 241);
  color: grey;
  display: flex;
  justify-content: center;
  border-radius: 3rem;
  width: fit-content;
  height: fit-content;
  padding-left: 1.5%;
  padding-right: 1.5%;
}

.btn-refuser-demande-notif:hover {
  background-color: rgb(221, 221, 221);
}

@media (max-width: 1255px) {
  .menu-dashboard1 li {
    display: none;
  }

  .menu-dashboard li {
    display: flex;
  }

  #btn-dash {
    display: block;
  }
}

@media only screen and (orientation: portrait) {
  .texte-home {
    font-size: 1.3rem;
    width: 90%;
  }

  .navbar-container {
    border-radius: 0 0 6rem 0;
  }

  .logoHome {
    /* border: 2px solid blanchedalmond; */
    width: 450px;
  }

  .evenement-navbar-icone {
    border-radius: 0 0 20px 20px;
    /* width: 70% !important; */
  }

  .menu-profil {
    margin-top: 6% !important;
    margin-left: 0%;
  }

  .texteMenus {
    display: none !important;
    visibility: hidden;
  }

  .navbar-collapse .icone {
    width: 30px !important;
    height: 30px !important;
    margin: 5px;
  }

  /* .notif-icone {
        margin-left: 0;
        width: 53% !important;
    } */

  /* .navbar-nav {
        text-align: left;
        padding-left: 0;
    }

    .navbar-nav a .row {}

    .navbar-nav img {
        margin-left: 0%;
        width: 40px;
    }

    .navbar-home a {
        text-align: left;
    }

    .navbar-home .row {
        display: inline-block;
    }

    .navbar-home .row p {
        margin-left: 10%;
    }

    .navbar-home svg {
        margin-right: 18px;
    } */
}
