.container-contacts {
  width: 600px;
  margin: auto;
}

.tab-contacts {
  font-size: 0.95rem;
  width: 100%;
  /* background-color: #17418d; */
  margin: auto;
}

.contact-item {
  margin: auto;
  border-radius: 2rem;
  box-shadow: 0px 0px 5px #17418d;
  padding: 1%;
  font-size: 0.95rem;
  color: #17418d;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.contact-item button {
  color: red !important;
  font-size: 100% !important;
}

.contact-item:hover {
  color: #3c2e94;
  cursor: pointer;
}

.contact-item button {
  border-radius: 7vw;
  height: fit-content;
  width: fit-content !important;
  border: none;
  background-color: #e4e4e4;
  color: #17418d !important;
}

.contact-item button:hover {
  background-color: #b3b1b1;
}

.logo-contact {
  /* margin-top: 5px; */
  height: 40px;
  width: auto;
}

#photoProfil {
  background-color: #e4e4e4;
  margin: 1px;
  width: 80px !important;
  height: 80px !important;
}

.btn-refuser {
  color: grey !important;
  background-color: rgb(241, 238, 238) !important;
  margin-bottom: 5px;
}

.btn-refuser:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(184, 3, 3) !important;
}

.btn-accepter {
  margin-bottom: 5px;
}

.MuiChip-root {
}

@media (max-width: 673px) {
  .contact-item,
  .contact-item button {
    /* background-color: #17418d; */
    font-size: 2.6vw;
    width: 100%;
  }

  .logo-contact {
    height: 30px;
    width: auto;
  }
}

@media only screen and (orientation: portrait) {
  .container-contacts {
    width: 100%;
    /* background-color: rgb(245, 240, 240); */
  }
}
