#username {
  margin: auto;
  border: none;
  text-align: center;
  color: #17418d;
}

#password {
  padding-left: 15px;
  text-align: center;
  font-size: 100%;
  color: #17418d;
  border: none;
}

#username::placeholder,
#password::placeholder {
  color: #17418d !important;
  opacity: 1;
}

.passwordContainer,
.usernameContainer {
  /* display: flex; */
  /* background-color: #c02820; */
  margin: auto;
  /* width: 300px; */
  height: fit-content;
}

.passwordContainer input,
.usernameContainer input {
  border-radius: 50px !important;
}

.souvenir {
  width: fit-content;
  margin: auto !important;
}

.error2 {
  display: none;
}

.box-login {
  width: 100%;
  margin: auto;
  height: fit-content;
}

.login-form {
  width: 300px;
  /* width: 45vh; */
  margin: auto;
  color: white;
  /* background-color: #009af4; */
}

.box-login form {
  margin: auto;
  /* width: 40%; */
}

.footer-login {
  /* position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.btn-connexion {
  border-radius: 20px;
  width: 170px;
  margin: auto;
  padding-left: 4%;
  padding-right: 4%;
  background-color: #e84d14;
  border: none;
  font-size: 100%;
}

.btn-connexion:hover:enabled {
  background-color: #ac380e;
}

.password-oublie,
.premiere-connexion {
  margin: auto;
  width: fit-content;
  color: white;
}

.password-oublie a,
.premiere-connexion a {
  color: white;
}

.video-login {
  position: absolute;
  /* border: 2px solid red; */
  z-index: 0;
  min-width: 100vw;
  min-height: 100vh;
  right: 0;
  bottom: 0%;
  /* top: 0; */
  border-radius: 0 0 250px 0;
  /* box-shadow: inset 0 0 0 2000px rgba(39, 29, 103, 0.5) !important; */
  /* filter: grayscale(100%); */
  filter: opacity(25%);
  object-fit: cover;
}

.login {
  position: relative;
  /* box-shadow: inset 0 0 0 2000px rgba(39, 29, 103, 1); */
  box-shadow: inset 0 0 0 2000px #17418d;
  /* min-height: 100dvh; */
  border-radius: 0 0 250px 0;
  z-index: 500;
  padding-top: 0;
  padding-bottom: 0;
}

.text-login {
  font-family: "Barlow-bold";
  font-size: 2.5rem;
  /* font-size: 2.9vw; */
  /* font-size: 5.5vh; */
  color: white;
  width: 80%;
  z-index: 600;
}

.logo-login {
  /* width: 16rem; */
  /* width: 38vh; */
  width: 250px;
  z-index: 600;
  /* margin-bottom: 5%; */
  top: 0;
  /* margin-top: 0; */
  margin-bottom: auto;
  margin-left: 0;
}

.btn-recup {
  border-radius: 5rem;
  background-color: #17418d;
}

.btn-recup2 {
  border-radius: 5rem;
}

.mentions-legales:hover {
  color: #a9dcfa !important;
}

.mentions-legales {
  color: white;
}
.access-container {
  margin-top: 20px;
}
.access-rule {
  color: #fff;
  text-align: center;
}

/* Media Query téléphone */
@media (max-width: 911px) {
  .error1 {
    display: none;
  }

  .error2 {
    display: flex;
  }
}

@media only screen and (orientation: portrait) {
  .logo-login {
    margin: auto;
  }

  .usernameContainer,
  .passwordContainer {
    width: 100%;
    margin: auto;
  }

  .btn-connexion {
    /* width: 60%; */
  }

  .souvenir {
    /* width: 250px; */
  }

  .box-login form {
    width: 100%;
  }

  .login,
  .video-login {
    border-radius: 0 0 5rem 0;
  }

  .text-login {
    /* background-color: #009af4; */
    width: 98%;
    margin: auto;
    font-size: 1.6rem;
  }
}
