.form-suppression {
  width: 600px !important;
  margin: auto;
  box-shadow: 0px 0px 5px #17418d;
  color: #17418d;
  text-align: center;
  border-radius: 20px;
  padding: 3%;
  margin-top: 4%;
  /* border: 1px solid #17418d !important; */
}

.form-suppression input {
  margin: auto;
  border: 1px solid #17418d !important;
}

@media only screen and (orientation: portrait) {
  .form-suppression {
    width: 90% !important;
  }
}
