.infos-profil {
  width: 1000px;
  margin: auto;
  margin-bottom: 2%;
  display: flex;
  justify-content: start;
  /* background-color: #f400d3; */
}

.coordonnees-container {
  /* border-top: 2px solid #1bace4; */
  color: #17418d;
}

.coordonnees-container .icone img {
  width: 30px;
}

.coordonnees-titre {
  color: #1bace4;
  /* font-weight: 700; */
  margin-top: 5px;
  font-family: "Barlow-bold";
}

.coordonnees-titre-infos {
  color: #1bace4;
  /* font-weight: 700; */
  font-family: "Barlow-bold";
}

.coordonnee-info {
  border-top: 2px solid #1bace4;
  color: #17418d;
  margin-bottom: 2%;
}

.profil-nom {
  font-size: 1.4rem;
  font-family: "Barlow-bold";
  color: #17418d;
}

.profil-coordonnees {
  color: #17418d;
}

.btn-valider {
  border: none;
  background-color: #1bace4;
  border-radius: 2rem;
  width: 100px;
  margin-right: 0;
}

.btn-modifier {
  border: none;
  background-color: #e84d14;
  border-radius: 2rem;
  width: 100px;
  padding-left: 3%;
  padding-right: 3%;
}

.btn-modifier:hover {
  background-color: #a8370e !important;
}

.container-gauche {
  width: 40%;
}

.container-droite {
  width: 60%;
}

.nom-profil1 {
  display: none;
  color: #17418d;
}

.modal-photo button {
  border-radius: 50px;
}

.list-profil {
  /* box-shadow: 1px 3px 10px 1px rgb(235, 235, 235); */
}

.intervention-item {
  margin: auto;
  margin-bottom: 2%;
  border-radius: 3rem;
  box-shadow: 0px 0px 5px black;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 500px;
  text-align: left;
  color: #17418d;
  background-color: white;
  /* font-size: 2rem; */
}

.photoProfil {
  background-color: #1bace4;
}

@media (max-width: 531px) {
  .intervention-item {
    width: 99%;
  }
}

@media (max-width: 1060px) {
  .nom-profil1 {
    display: block;
  }

  .nom-profil2 {
    display: none;
  }

  .infos-profil {
    width: 100%;
  }

  .container-gauche {
    width: 100%;
  }

  .container-droite {
    width: 100%;
  }
}

@media only screen and (orientation: portrait) {
  .infos-profil {
    width: 100%;
  }

  .coordonnees-container2 {
  }
}
